import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Stack } from "@mui/system";
import { Typography, Button, Checkbox, FormHelperText } from "@mui/material";
import { postCall } from "../Api/helpers";
import { CREATE_STOCK } from "../Api/apiPath";
import { useState } from "react";
import { toast } from "react-toastify";
import GlobalContext from "../Global/GlobalContextProvider";
export default function BasicTextFields({ handleClose }) {
  const { setIsSubscriptionTableUpdate, isSubscriptionTableUpdate } =
    React.useContext(GlobalContext);
  const [ricName, setRicName] = useState("");
  const [stockName, setStockName] = useState("");
  const [symbolTicker, setSymbolTicker] = useState("");
  const [industry, setIndustry] = useState("");
  const [status, setStatus] = React.useState(true);
  const [error, setError] = React.useState("");
  const [disabled, setDisabled] = React.useState(true);
  const handleSwitch = (event) => {
    setStatus(event.target.checked);
  };

  const formHandler = async (event) => {
    event.preventDefault();
    let dataobj = {
      ric: ricName,
      stock_name: stockName,
      symbol_ticker: symbolTicker,
      industry: industry,
      status: status == true ? "TRUE" : "FALSE",
    };
    let { isApiConnectionSucceess, data } = await postCall({
      path: `${CREATE_STOCK}`,
      Data: dataobj,
    });
    if (data.error) {
      setError(data.message);
      toast.error(data.message);
    } else {
      setIsSubscriptionTableUpdate(true);
      handleClose();
      toast.success(data.message);
    }
  };

  React.useEffect(() => {
    if (ricName && stockName && symbolTicker) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [ricName, stockName, symbolTicker]);

  return (
    <>
      <Stack>
        <form onSubmit={formHandler}>
          <TextField
            style={{ marginBottom: "10px" }}
            fullWidth
            required
            id="outlined-basic"
            label="RIC"
            variant="outlined"
            // onChange={(e) => onChangeHandler(e)}
            onChange={(e) => setRicName(e.target.value)}
          />

          <TextField
            style={{ marginBottom: "10px" }}
            fullWidth
            required
            id="outlined-basic"
            label="Stock Name"
            variant="outlined"
            // onChange={(e) => onChangeHandler(e)}
            onChange={(e) => setStockName(e.target.value)}
          />

          <TextField
            style={{ marginBottom: "10px" }}
            fullWidth
            required
            id="outlined-basic"
            label="Symbol Ticker"
            variant="outlined"
            // onChange={(e) => onChangeHandler(e)}
            onChange={(e) => setSymbolTicker(e.target.value)}
          />

          <TextField
            fullWidth
            required
            id="outlined-basic"
            label="Industry"
            variant="outlined"
            // onChange={(e) => onChangeHandler(e)}
            onChange={(e) => setIndustry(e.target.value)}
          />

          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ mt: 1 }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Subscription Status : </Typography>
              <Checkbox
                checked={status}
                onChange={handleSwitch}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Stack>
          </Box>

          <FormHelperText sx={{ color: "red" }}>{error}</FormHelperText>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
            }}
          >
            <Button
              onClick={handleClose}
              sx={{ marginTop: "10px" }}
              variant="contained"
              color="error"
              fullWidth
            >
              Cancel
            </Button>
            <Button
              type="submit"
              fullWidth
              sx={{ marginTop: "10px" }}
              variant="contained"
              disabled={disabled}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Stack>
    </>
  );
}
