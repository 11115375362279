import React from "react";

const GlobalContext = React.createContext({});

export function GlobalContextProvider({ children }) {
  const [isSubscriptionTableUpdate, setIsSubscriptionTableUpdate] =
    React.useState(true);
  const [token, setToken] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [isauth, setUserAuth] = React.useState(false);

  return (
    <GlobalContext.Provider
      value={{
        isSubscriptionTableUpdate,
        token,
        userId,
        isauth,
        setIsSubscriptionTableUpdate,
        setToken,
        setUserId,
        setUserAuth,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}
export default GlobalContext;
