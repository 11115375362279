import React from "react";
import Main from "./component/main/Main";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./component/login/Login";
import GlobalContext from "./Global/GlobalContextProvider";
import { getItem } from "./Api/localStorageHelpers";
import { getCall } from "./Api/helpers";

function App() {
  const { isauth, setUserAuth } = React.useContext(GlobalContext);
  React.useEffect(() => {
    authData();
  }, []);

  const authData = async () => {
    !getItem({ key: "UserInfo" }) && setUserAuth(false);
    if (getItem({ key: "UserInfo" })) {
      const token = getItem({ key: "UserInfo" });
      let { isApiConnectionSucceess, data } = await getCall({
        path: `/api/userauth/${token}`,
      });
      {
        data.isUserAutenticated && setUserAuth(true);
      }
    }
  };
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/" element={!isauth ? <Login /> : <Main />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
