import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, TextField } from "@mui/material";
import DeleteStockBtn from "./DeleteStockBtn";
import EditStockBtn from "./EditStockBtn";
import { GET_STOCK } from "../Api/apiPath";
import { getCall } from "../Api/helpers";
import AddStockBtn from "./AddStockBtn";
import GlobalContext from "../Global/GlobalContextProvider";
import Loader from "../uicomponent/Loader";

export default function Table() {
  const { setIsSubscriptionTableUpdate, isSubscriptionTableUpdate, isauth } =
    React.useContext(GlobalContext);
  const [stocks, setStocks] = useState([]);
  const [filterdStocks, setFilterdStocks] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);

  const requestSearch = (searchedVal) => {
    const filteredRows = stocks.filter((item) => {
      return Object.values(item).join('').toLowerCase().includes(searchedVal.toLowerCase())
    })
    setFilterdStocks(filteredRows);
  };

  const columns = [
    {
      field: "ric",
      headerName: "RIC",
      flex: 1,
      headerClassName: "ric_table_column",
    },
    { field: "is_subscription_active", headerName: "Subscription", flex: 1 },
    { field: "stock_name", headerName: "Stock Name", flex: 2 },
    { field: "symbol_ticker", headerName: "Symbol Ticker", flex: 1 },
    { field: "industry", headerName: "Industry", flex: 1 },
    { field: "is_past_data_fetched", headerName: "Past Data", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      flex: 1,
      disableColumnMenu: true,
      headerClassName: "table_actions_column",
      align: "center",
      renderCell: (cellValues) => (
        <EditStockBtn cellValues={cellValues}>Edit</EditStockBtn>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      flex: 1,
      marginLeft: "10px",
      disableColumnMenu: true,
      headerClassName: "table_actions_column",
      align: "center",
      renderCell: (cellValues) => (
        <DeleteStockBtn cellValues={cellValues}>Delete</DeleteStockBtn>
      ),
    },
  ];

  const getApiData = async () => {
    if (isSubscriptionTableUpdate) {
      let { isApiConnectionSucceess, data } = await getCall({
        path: `${GET_STOCK}`,
      });
      setStocks(data["data"]);
      setFilterdStocks(data["data"]);
      setIsSubscriptionTableUpdate(false);
      setDataLoading(false);
    }
  };

  useEffect(() => {
    getApiData();
  }, [isSubscriptionTableUpdate]);

  return (
    <>
      {dataLoading ? (
        <Loader />
      ) : (
        <Box sx={{ height: "80vh", maxWidth: "100vw", margin: "auto" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TextField
              style={{ margin: "10px" }}
              label="Search"
              variant="outlined"
              type="search"
              onChange={(e) => requestSearch(e.target.value)}
            />
            <Box sx={{ marginRight: "10px" }}>
              <AddStockBtn />
            </Box>
          </Box>

          <DataGrid
            style={{ margin: "0px 10px" }}
            rows={filterdStocks.length < stocks.length ? filterdStocks : stocks}
            columns={columns}
            getRowId={(row) => row.ric}
            pageSize={12}
            disableSelectionOnClick={true}
          />
        </Box>
      )}
    </>
  );
}
