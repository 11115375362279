import React from 'react'
import Navbar from '../../uicomponent/Navbar'
import Table from '../../uicomponent/Table';

function Main() {
  return (
    <>
        <Navbar />
        <Table />
    </>
  )
}

export default Main