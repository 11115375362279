import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { toast} from 'react-toastify';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { removeItem } from '../Api/localStorageHelpers';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import SyncIcon from '@mui/icons-material/Sync';
import useMediaQuery from "@mui/material/useMediaQuery";
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import { HARD_SYNC } from '../Api/apiPath';
import { getCall } from '../Api/helpers';

export default function Navbar() {
  const history = useNavigate();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));


  const logout = () => {
    removeItem({ key: 'UserInfo' })
    removeItem({ key: 'userId' })
    history('/login')
  }
  const hardsync = async () => {
    setOpen(false)
    let { isApiConnectionSucceess, data } = await getCall({
      path: `${HARD_SYNC}`,
    });
    console.log(data);
    toast.success(data.message);

  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Stock Lists
          </Typography>
          <Button startIcon={<SyncIcon />} onClick={handleClickOpen} variant="contained" >
            Hard Sync
          </Button>
          <Button startIcon={<LogoutIcon />} onClick={logout} variant="contained" >
            Logout
          </Button>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Are you sure to perform hard-sync?"}
            </DialogTitle>

            <DialogActions sx={{ padding: "20px" }}>
              <Button onClick={handleClose} sx={{ marginTop: "10px" }} color="error" variant="contained">
                No
              </Button>
              <Button onClick={hardsync} variant="contained" sx={{ marginTop: "10px" }} >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </Toolbar>
      </AppBar>
    </Box>
  );
}