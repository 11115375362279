import React from 'react';
import { Backdrop } from '@mui/material'
import FadeLoader from "react-spinners/FadeLoader";

function Loader() {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <FadeLoader color="#1976d2" />
    </Backdrop>
  )
}

export default Loader