import * as React from "react";
import { toast } from 'react-toastify';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Stack } from "@mui/system";
import { deleteCall } from "../Api/helpers";
import { DELETE_STOCK } from "../Api/apiPath";
import GlobalContext from "../Global/GlobalContextProvider";

export default function ResponsiveDialog({ cellValues }) {
  const { setIsSubscriptionTableUpdate, isSubscriptionTableUpdate } = React.useContext(GlobalContext);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
  };
  // const handleSubmit = async () => {
  //   let { isApiConnectionSucceess, data, e } = await deleteCall({
  //     path: `${DELETE_STOCK}`,
  //     dataObj: cellValues.id,
  //   });
  //   setOpen(false);
  //   setIsSubscriptionTableUpdate(true);
  //   toast.success(data.message);
  // };

  return (
    <Box>
      <Stack direction="row" spacing={2}>
        <Button
          className="delete_stock_btn"
          variant="outlined"
          color="error"
          onClick={handleClickOpen}
          startIcon={<DeleteIcon />}
        >
          DELETE
        </Button>
      </Stack>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Only admin can delete a stock!"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} sx={{ m: "10px" }} variant="contained">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
