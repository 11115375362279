import React from "react";
import { TextField, Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { USER } from "../../Global/constant";
import "./Login.css";
import { VALIDATE_USER } from "../../Api/apiPath";
import { getCall } from "../../Api/helpers";
import { postItem } from "../../Api/localStorageHelpers";
import GlobalContext from "../../Global/GlobalContextProvider";
import { toast } from "react-toastify";

function Login() {
  const { setUserAuth, setToken, setUserId, setIsSubscriptionTableUpdate } =
    React.useContext(GlobalContext);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const history = useNavigate();

  const onSubmit = async (userdata) => {
    let path = `${VALIDATE_USER}/${userdata.username}/${userdata.password}`;
    let { isApiConnectionSucceess, data, e } = await getCall({ path });
    if (data.data) {
      postItem({ key: "UserInfo", data: data.token });
      postItem({ key: "userId", data: data.data.id });
      setUserAuth(true);
      setToken(data.token);
      setUserId(data.data.id);
      setIsSubscriptionTableUpdate(true);
      history("/");
    } else {
      toast.error(data.message);
      reset();
    }
  };
  return (
    <>
      <Box className="container" sx={{ height: "100vh" }}>
        <Box>
          <Typography variant="h3" color="primary">
            Login
          </Typography>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className="container">
            <Box className="form_fields">
              <TextField
                sx={{ mt: 2 }}
                variant="standard"
                id="demo-helper-text-aligned"
                label="User Name*"
                name="username"
                {...register("username", { required: USER.USERNAME_REQUIRED })}
                error={Boolean(errors.username)}
                helperText={errors.username?.message}
              />
              <TextField
                variant="standard"
                sx={{ mt: 1 }}
                id="demo-helper-text-aligned"
                label="Password*"
                name="password"
                {...register("password", { required: USER.PASSWORD_REQUIRED })}
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
              />
            </Box>

            <Box sx={{ marginTop: "40px" }}>
              <Button
                variant="outlined"
                type="submit"
                className="submit"
                color="primary"
              >
                Login
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
}

export default Login;
