import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddStockForm from "./AddStockForm";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

export default function TransitionsModal() {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button startIcon={<AddCircleOutlineIcon />} onClick={handleOpen} variant="contained"
        fullWidth >
        Add Stock
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography textAlign="center" id="transition-modal-title" variant="h6" component="h2">
              Create Subscription Stock
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              <AddStockForm handleClose={handleClose} />
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
