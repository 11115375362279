import * as React from "react";
import { toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Checkbox, Stack, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { UPDATE_SUBSCRIPTION_STATUS } from "../Api/apiPath";
import { putCall } from "../Api/helpers";
import GlobalContext from "../Global/GlobalContextProvider";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

export default function TransitionsModal({ cellValues }) {
  const { setIsSubscriptionTableUpdate, isSubscriptionTableUpdate } =
    React.useContext(GlobalContext);
  const [open, setOpen] = React.useState(false);
  const [ric, setRic] = React.useState("");
  const [stockName, setStockName] = React.useState("");
  const [symbolTicker, setSymbolTicker] = React.useState("");
  const [industry, setIndustry] = React.useState("");
  const [disabled, setDisabled] = React.useState(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setStatus(cellValues.row.is_subscription_active == "TRUE" ? true : false);
    setDisabled(true);
    setOpen(false);
  };

  const [status, setStatus] = React.useState(
    cellValues.row.is_subscription_active == "TRUE" ? true : false
  );

  const handleChange = (event) => {
    setOpen(true);
    setRic(cellValues.id);
    setStockName(cellValues.row.stock_name);
    setSymbolTicker(cellValues.row.symbol_ticker);
    setIndustry(cellValues.row.industry);
  };

  const handleSwitch = (event) => {
    setDisabled((((cellValues.row.is_subscription_active == "TRUE" ? true : false) == event.target.checked) && (industry == cellValues.row.industry)))
    setStatus(event.target.checked);
  };
  const handleIndustryChange = (event) => {
    setDisabled((((cellValues.row.is_subscription_active == "TRUE" ? true : false) == status) && (cellValues.row.industry == event.target.value)))
    setIndustry(event.target.value);
  };
  const handleSubmit = async () => {
    let dataobj = {
      ric,
      status: status == true ? "TRUE" : "FALSE",
      industry: industry,
      stock_name:stockName,
      symbol_ticker:symbolTicker
    };
    let { isApiConnectionSucceess, data, e } = await putCall({
      path: `${UPDATE_SUBSCRIPTION_STATUS}`,
      updatedData: dataobj,
    });
    if (isApiConnectionSucceess) {
      setOpen(false);
      setIsSubscriptionTableUpdate(true);
      setDisabled(true);
      toast.success(data.message);
    }
  };

  return (
    <Stack direction="row" spacing={2}>
      <Button
        className="edit_stock_btn"
        variant="outlined"
        onClick={handleChange}
        startIcon={<EditIcon />}
      >
        Edit
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography
              textAlign="center"
              id="transition-modal-title"
              variant="h6"
              component="h2"
            >
              Edit Subscription of Stock
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              label="RIC"
              variant="outlined"
              value={ric}
              disabled
              sx={{ marginTop: "10px" }}
            />
            <TextField
              fullWidth
              id="outlined-basic"
              label="Stock Name"
              variant="outlined"
              value={stockName}
              onChange={(e)=>{setStockName(e.target.value)}}
              sx={{ marginTop: "10px" }}
            />
            <TextField
              fullWidth
              id="outlined-basic"
              label="Symbol Ticker"
              variant="outlined"
              value={symbolTicker}
              onChange={(e)=>{setSymbolTicker(e.target.value)}}
              sx={{ marginTop: "10px" }}
            />
            <TextField
              fullWidth
              id="outlined-basic"
              label="Industry"
              variant="outlined"
              value={industry}
              onChange={handleIndustryChange}
              sx={{ marginTop: "10px" }}
            />
            <Typography id="transition-modal-description" sx={{ mt: 1 }}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Subscription Status : </Typography>
                  <Checkbox
                    checked={status}
                    onChange={handleSwitch}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Stack>
              </Box>
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
              }}
            >
              <Button
                onClick={handleClose}
                sx={{ marginTop: "10px" }}
                variant="contained"
                color="error"
                fullWidth
              >
                Cancel
              </Button>
              <Button
                fullWidth
                onClick={handleSubmit}
                sx={{ marginTop: "10px" }}
                variant="contained"
                // disabled={disabled}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Stack>
  );
}
